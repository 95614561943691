import React from "react";
import LinkedinSVG from "../components/svg/linkedin";
import GithubSVG from "../components/svg/github";
import Animations from "./animations";

function Home() {
  return (
    <>
      <div className="headerContainer">
        <div className="header"></div>
      </div>
      <div className="container">
        <div className="cardUser">
          <img
            className="imgUser"
            alt="Sidnei Rafael Biz"
            src="https://media-exp1.licdn.com/dms/image/C4E03AQGNgUJy4OdZhw/profile-displayphoto-shrink_200_200/0/1581313208162?e=1623888000&v=beta&t=T0cF5ZHFJhYLpV7li79KiSF8UIDbCxXx9cf5I9l19T4"
          />
          <div className="userData">
            <b>Sidnei Rafael Biz</b>
            <span className="profissionalName">Full Stack Developer</span>
            <div className="divider"></div>
            <div className="social">
              <LinkedinSVG
                className="socialIcon"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/sidneirafaelbiz/")
                }
              />
              <GithubSVG
                className="socialIcon"
                onClick={() => window.open("https://github.com/sidneibiz")}
              />
            </div>
          </div>
        </div>
        <div className="menu">
          <div>Animações</div>
        </div>
      </div>
      <Animations></Animations>
    </>
  );
}

export default Home;
