import * as React from "react";

function LinkedinSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M437.019 74.982C388.667 26.628 324.379 0 256 0S123.333 26.628 74.981 74.982C26.628 123.333 0 187.621 0 256s26.628 132.667 74.981 181.019C123.333 485.372 187.621 512 256 512s132.667-26.628 181.019-74.982C485.372 388.667 512 324.379 512 256s-26.628-132.667-74.981-181.018zM256 495.832C123.756 495.832 16.168 388.244 16.168 256S123.756 16.168 256 16.168 495.832 123.756 495.832 256 388.244 495.832 256 495.832z" />
      <path d="M180.547 204.8h-53.895a8.084 8.084 0 00-8.084 8.084v161.684a8.084 8.084 0 008.084 8.084h53.895a8.084 8.084 0 008.084-8.084V212.884a8.083 8.083 0 00-8.084-8.084zm-8.084 161.684h-37.726V220.968h37.726v145.516zM148.21 107.789c-22.289 0-40.421 18.132-40.421 40.421 0 22.289 18.132 40.421 40.421 40.421 22.289 0 40.421-18.132 40.421-40.421s-18.132-40.421-40.421-40.421zm0 64.674c-13.373 0-24.253-10.88-24.253-24.253 0-13.372 10.879-24.253 24.253-24.253 13.373 0 24.253 10.88 24.253 24.253s-10.879 24.253-24.253 24.253zM331.453 204.8c-16.054 0-31.87 4.156-45.811 11.896v-3.811a8.084 8.084 0 00-8.084-8.084h-43.116a8.084 8.084 0 00-8.084 8.084v161.684a8.084 8.084 0 008.084 8.084h53.895a8.084 8.084 0 008.084-8.084v-58.04c0-19.008 4.207-41.665 24.253-41.665 14.959 0 21.095 12.619 23.247 26.923.59 3.922 3.989 6.808 7.957 6.808 4.979 0 8.722-4.464 7.954-9.382-4.059-25.974-17.828-40.517-39.158-40.517-25.688 0-40.421 21.08-40.421 57.833v49.956h-37.726V220.968h26.947v10.63c0 2.507 1.044 4.956 3.004 6.519a8.095 8.095 0 0010.138.012c14.001-11.226 30.888-17.161 48.837-17.161 46.537 0 67.368 39.249 67.368 78.147v67.368h-37.726v-26.62a8.06 8.06 0 00-8.06-8.061h-.047a8.06 8.06 0 00-8.06 8.061v34.704a8.084 8.084 0 008.084 8.084h53.895a8.084 8.084 0 008.084-8.084v-75.453c-.002-55.53-34.353-94.314-83.538-94.314z" />
    </svg>
  );
}

export default LinkedinSVG;
