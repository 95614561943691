import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CodeMirror from "@uiw/react-codemirror";
import "codemirror/keymap/sublime";
import "codemirror/theme/base16-dark.css";
import "./coderender.css";

function CodeRender({
  htmlValue,
  cssValue,
  cssWidth = "48%",
  cssHeight = "275px",
  htmlWidth = "48%",
  htmlHeight = "275px",
}) {
  const [originalCode] = useState(htmlValue);
  const [originalStyle] = useState(cssValue);
  const [code, setCode] = useState(htmlValue);
  const [style, setStyle] = useState(cssValue);
  const [contentRef, setContentRef] = useState(null);
  const frame = contentRef?.contentWindow?.document;

  const executeFont = () => {
    ReactDOM.render(
      <div style={{ style }} dangerouslySetInnerHTML={{ __html: code }} />,
      frame.body
    );
    ReactDOM.render(<style>{style}</style>, frame.head);
  };

  const setDefault = () => {
    setCode(originalCode);
    setStyle(originalStyle);
    executeFont();
  };

  useEffect(() => {
    if (frame) {
      executeFont();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame]);

  return (
    <>
      <div className="containerCode">
        <div className="htmlCode">
          <div className="heading">
            <div className="htmlBlob" />
            <div className="title">HTML</div>
          </div>
          <CodeMirror
            width={"100%"}
            value={code?.toString()}
            onChange={(c) => setCode(c.getValue())}
            options={{
              theme: "base16-dark",
              keyMap: "sublime",
              mode: "html",
            }}
          />
        </div>
        <div className="cssCode">
          <div className="heading">
            <div className="cssBlob" />
            <div className="title">CSS</div>
          </div>
          <CodeMirror
            width={"100%"}
            value={style?.toString()}
            onChange={(c) => setStyle(c.getValue())}
            options={{
              theme: "base16-dark",
              keyMap: "sublime",
              mode: "css",
            }}
          />
        </div>
      </div>
      <div className="containerBtn">
        <button onClick={() => setDefault()}>Original</button>
        <button onClick={executeFont}>Executar</button>
      </div>
      <div className="result">
        <div className="resultContainer">
          <div className="heading">
            <div className="htmlBlob" />
            <div className="cssBlob" />
            <div className="title">RESULTADO</div>
          </div>
          <div className="output">
            <iframe ref={setContentRef} title="output" id="output"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default CodeRender;
